import React, { useEffect, useState } from "react";
import {
    Box,
    Container,
    Grid,
    makeStyles,
    Tab,
    Tabs,
    Typography,
    Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useIdleTimer } from 'react-idle-timer'
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import PropTypes from "prop-types";
import Layout from "./layout/Layout";
import useSocket from "../hooks/useSocket";
import usePeer from "../hooks/usePeer";
import { v4 as uuidv4 } from "uuid";
import UploadSteps from "./uploadSteps";
import Stepper from "../ui/Stepper";
import QRCode from "react-qr-code";
import DownloadStep from "./download/DownloadStep";
import { SOCKET_URL } from "../utils/constants";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "start",
        paddingTop: 30,
        paddingBottom: 20,
    },
    header: {
        marginTop: 20,
        marginBottom: 30,
        display: "flex",
        flexDirection: "row",
    },
    header1: {
        display: "flex",
        flexDirection: "column",
    },
    heading: {
        marginLeft: 50,
        marginTop: 30,
        width: 400,
    },
    topView: {
        marginTop: "1px",
        backgroundColor: "#eeeeee",
    },
    folders1: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "start",
        paddingTop: 20,
        paddingBottom: 20,
        marginBottom: 20,
    },
    Report1: {
        marginRight: 10,
        marginTop: 10,
    },
    Report2: {
        fontWeight: "bold",
    },
    reportButton: {
        padding: 10,
        backgroundColor: "black",
        color: "white",
        fontWeight: "bold",
        height: 30,
        textTransform: "capitalize",
        marginLeft: 30,
        marginTop: -20,
    },
    tabText: {
        textTransform: "none"
    },
    alertTitleContent: {
        display: 'flex',
    },
    alertTitle: {
        alignSelf: 'center',
        fontSize: 20,
        paddingLeft: 10,
        fontWeight: 'bold'
    },
    buttonContent: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        flexDirection: 'row',
        paddingBottom: 20,
        paddingLeft: 25,
        paddingRight: 20,
    },
    buttonStyle: {
        color: '#09cdb7',
        outlineColor: '#09cdb7'
    },
    countdownTextPadding: {
        paddingTop: 10,
        fontWeight: 'bold'
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            style={{ marginTop: "1px", backgroundColor: "#eeeeee" }}
            id={`webrtc-tabpanel-${index}`}
            aria-labelledby={`webrtc-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `webrtc-tab-${index}`,
        "aria-controls": `webrtc-tabpanel-${index}`,
    };
}

function Home() {
    let history = useHistory();
    const { t } = useTranslation("translation");
    const classes = useStyles();
    const SOCKET_PATH = `${SOCKET_URL}/webrtcPeer`;
    const [file, setFile] = useState("");
    const [fileName, setFileName] = useState("");
    const [tab, setTab] = useState(0);
    const [qr, setQR] = useState("");
    const [counter, setCounter] = useState(0);
    const [step, setStep] = useState(1);
    const steps = [1, 2];
    const [files, setFiles] = useState([]);
    const [folders, setFolders] = useState([]);
    const [loader, setLoader] = useState(false);
    const [headerTitle, setHeaderTitle] = useState("DOWNLOAD_FROMAPP_DESC");
    const [isDocToUser, setIsDocToUser] = useState(false);
    const [isUserToDoc, setIsUserToDoc] = useState(false);
    const [countdown, setCountdown] = useState(30);
    const [timeout, setTimeoutIdeal] = useState(60000)
    const [isIdle, setIsIdle] = useState(false)
    const [openAlert, setOpenAlert] = useState(false);
    const [modalInterval, setModalInterval] = useState(null);
    const [startCountdownInterval, setStartCountdownInterval] = useState(false);
    const handleOnActive = () => setIsIdle(false)
    const handleOnIdle = () => setIsIdle(true)
    const {
        reset,
        pause,
    } = useIdleTimer({
        timeout,
        onActive: handleOnActive,
        onIdle: handleOnIdle,
    })

    const downloadTitles = [
        {
            name: "PATIENT_QRCODE",
            classes: "stepperTitleLeft",
        },
        {
            name: "DOWNLOAD_DOC",
            classes: "stepperTitleRight",
        },
    ];

    const uploadTitles = [
        {
            name: "SELECT_FOLDER",
            classes: "stepperTitleLeft",
        },
        {
            name: "ADD_OR_DELETE_DOC",
            classes: "stepperTitleRight",
        },
    ];
    const [titles, setTitles] = useState(downloadTitles);
    const { socket, connected } = useSocket(SOCKET_PATH);
    const { onlineStatus, socketID, peerConnection } = usePeer(connected, socket);

    const fileType = {
        bmp: "image/bmp",
        BMP: "image/bmp",
        jpg: "image/jpeg",
        JPG: "image/jpeg",
        jpeg: "image/jpeg",
        JPEG: "image/jpeg",
        doc: "application/msword",
        DOC: "application/msword",
        docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        DOCX: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        pdf: "application/pdf",
        PDF: "application/pdf",
        png: "image/png",
        PNG: "image/png",
        zip: "application/zip",
        ZIP: "application/zip"
    };

    const handleReceiveMessage = (event) => {
        // console.log('event.currentTarget.label  ', event.currentTarget.label );
        if (event.currentTarget.label === "shareJSON") {
            const data = JSON.parse(event.data);
            const _files = data.files;
            const _folders = data.folders;
            setIsDocToUser(data.isDocToUser);
            setIsUserToDoc(data.isUserToDoc);
            setFiles(_files);
            setFolders(_folders);

            if (data.isUserToDoc && data.isDocToUser) {
                setTab(0);
                setStep(2);
                setHeaderTitle("DOCUMENTS_HEADER_ST2");
                setTitles(downloadTitles);
            } else if (!data.isUserToDoc && data.isDocToUser) {
                setTab(1);
                setStep(1);
                setHeaderTitle("DOCUMENT_HEADER_UPLOAD");
                setTitles(uploadTitles);
            } else if (data.isUserToDoc && !data.isDocToUser) {
                setTab(0);
                setStep(2);
                setHeaderTitle("DOCUMENTS_HEADER_ST2");
                setTitles(downloadTitles);
            }
        } else {
            if (event.data !== "START" && event.data !== "END" && event.data !== "") {
                setLoader(true);
                setFile((prev) => prev + event.data);
            }

            if (event.data === "END") {
                setLoader(false);
                setFileName(event.currentTarget.label);
            }
        }
    };

    const handleReceiveChannelStatusChange = (event) => {
        // console.log('send channel status: ', event)
    };

    const receiveChannelCallback = (event) => {
        const { channel } = event;
        channel.onmessage = handleReceiveMessage;
        channel.onopen = handleReceiveChannelStatusChange;
        channel.onclose = handleReceiveChannelStatusChange;
    };

    const setData = () => {
        const object = { SocketId: socket.id, RoomId: uuidv4() };
        setQR(JSON.stringify(object));
    };

    const handleChange = (event, newValue) => {
        setTab(newValue);
        if (newValue === 0) {
            setStep(2);
            setTitles(downloadTitles);
            setHeaderTitle("DOCUMENTS_HEADER_ST2");
       
        } else {
            setStep(1);
            setHeaderTitle("DOCUMENT_HEADER_UPLOAD");
            setTitles(uploadTitles);
           
        }
    };

    useEffect(() => {
        try {
            if (fileName) {
                // console.log('Download File ');
                const fileExt = fileName.split(".").pop();
                const ext = fileType[fileExt];
                const a = document.createElement("a"); //Create <a>
                a.href = `data:${fileType[ext]};base64,` + file; //Image Base64 Goes here
                a.download = fileName; //File name Here
                a.hidden = true;
                a.target = "_blank";
                a.click(); //Downloaded file
                setLoader(false);

                setFileName("");
                setFile("");
            }
        } catch (e) {
            console.log(e);
        }
    }, [file, fileName]);

    useEffect(() => {
        let timer = null;
        if (onlineStatus === "New") {
            timer = setInterval(() => {
                if (socket?.id) {
                    setData();
                    setCounter(counter + 1);
                }
            }, 20000);

            if (counter === 20) {
                clearInterval(timer);
            }
        }

        return () => {
            clearInterval(timer);
        };
    }, [counter, socket]);

    useEffect(() => {
        if (onlineStatus === "Closed") {
            history.push("/failed");
        }
        if (peerConnection) {
            peerConnection.ondatachannel = receiveChannelCallback;
        }
        if(peerConnection?.connectionState === 'connected'){
            setTimeoutIdeal(60000 * 5)
        }
        if (connected && socket?.id) {
            setData();
        }
        if (isMobile) {
            setHeaderTitle("MOBILE_NOT_SUPPORTED");
        }
    }, [peerConnection, socket, onlineStatus, connected]);

    useEffect(() => {
        if(isIdle){
            setStartCountdownInterval(true);
            setOpenAlert(true);
            startCountdown();
        }
    }, [isIdle, startCountdownInterval])

    const handleClose = (event, action) => {
        if (action && action === "backdropClick")
            return;
        window.location.reload();
    };

    const continueProcess = () => {
        setStartCountdownInterval(false);
        setCountdown(30);
        setOpenAlert(false);
        clearIntervalTime();
        reset();
    };

    const clearIntervalTime = () => {
        clearInterval(modalInterval)
    }

    const startCountdown = () => {
        if (countdown > 0 && countdown !== 0 && startCountdownInterval){
            const intervalId = setInterval(() => {
                setCountdown((time) => {
                    if (time > 1) {
                        return time - 1;
                    }
                    else{
                        window.location.reload();
                        return 0;
                    }
                });
            }, 1000);
            setModalInterval(intervalId);
        }
    }

    const isUploadProcessing = (fileIsProcessing) => {
        if (fileIsProcessing) {
            pause();
        } else if(!fileIsProcessing) {
            reset();
        }
    };
    const downloadProcessing = () =>{
        pause();
    }

    return (
        <>
            {isMobile && (
                <Layout
                    loader={loader}
                    setLoader={setLoader}
                    title={headerTitle}
                    fluid={false}
                >
                    <Container className={classes.topView}></Container>
                </Layout>
            )}

            {!isMobile && (
                <Layout
                    loader={loader}
                    setLoader={setLoader}
                    title={headerTitle}
                    fluid={false}
                >
                    <Container>
                        <Dialog
                            open={openAlert}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            PaperProps={{ style: { borderRadius: 0 }}}>
                            <DialogTitle id="alert-dialog-title" className={classes.alertTitleContent}>
                                <Typography><img src="/clock-filled.png" width={50} height={50} alt="Clock"/></Typography>
                                <Typography className={classes.alertTitle}>{t("TIMEOUT")}</Typography>
                            </DialogTitle>
                            <DialogContent>
                                <Typography id="alert-dialog-description1">
                                    {t('YOU_HAVE_BEEN_IDLE')}
                                </Typography>
                                <Typography id="alert-dialog-description2">
                                    {t('YOUR_SESSION_WILL_BE_RESET_1')}{' '}{t('YOUR_SESSION_WILL_BE_RESET_2')}
                                </Typography>
                                <Typography className={classes.countdownTextPadding}>
                                    {t('COUNTDOWN')}: {countdown}
                                </Typography>
                            </DialogContent>
                            <div className={classes.buttonContent}>
                                <Button
                                    onClick={() => continueProcess()}
                                    variant="contained"
                                    color="primary"
                                >
                                    {t('CONTINUE')}
                                </Button>
                                <Button
                                    onClick={() => handleClose()}
                                    variant="outlined"
                                    color="primary"
                                    classes={{ label: classes.buttonStyle }}
                                >
                                    {t('RESET')}
                                </Button>
                            </div>
                        </Dialog>
                        {onlineStatus === "New" && (
                            <Grid
                                className={classes.root}
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Stepper titles={titles} step={step} steps={steps} />
                                <div className={classes.header}>
                                    {!openAlert ? <QRCode value={qr} /> : null}
                                </div>
                            </Grid>
                        )}

                        {onlineStatus === "Online" && (
                            <>
                                <Tabs
                                    value={tab}
                                    onChange={handleChange}
                                    aria-label="webrtc tabs"
                                >
                                    <Tab className={classes.tabText} disabled={!isUserToDoc} label={t("DOWNLOAD")} {...a11yProps(0)} />
                                    <Tab className={classes.tabText} disabled={!isDocToUser} label={t("UPLOAD")} {...a11yProps(1)} />
                                </Tabs>
                                <Stepper titles={titles} step={step} steps={steps} />
                                <TabPanel value={tab} index={0}>
                                    <DownloadStep
                                        socket={socket}
                                        socketID={socketID}
                                        peerConnection={peerConnection}
                                        files={files}
                                        folders={folders}
                                        downloadProcessing={downloadProcessing}
                                        setLoader={setLoader}
                                        history={history}
                                    />
                                </TabPanel>
                                <TabPanel value={tab} index={1}>
                                    <UploadSteps
                                        socket={socket}
                                        socketID={socketID}
                                        setLoader={setLoader}
                                        setStep={setStep}
                                        step={step}
                                        isUploadProcessing={isUploadProcessing}
                                        peerConnection={peerConnection}
                                    />
                                </TabPanel>
                            </>
                        )}

                        <Grid container className={classes.folders1}></Grid>
                    </Container>
                </Layout>
            )}
        </>
    );
}

export default Home;
