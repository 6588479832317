import React from "react";
import {Container, Grid, makeStyles, Typography,} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Layout from "./layout/Layout";
import Footer from "./layout/Footer";
import Header from "./layout/Header";

const useStyles = makeStyles(() => ({
    folders1: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "start",
        paddingTop: 40,
        paddingBottom: 20,
        marginBottom: 20,
        
    },
    topView: {
        marginTop: 10,
    },
    uploadText: {
        fontSize: 20,
        fontWeight: 'bold'
    },

    uploadText1: {
        width: "40%",
        marginBottom: 30,
        fontSize: 20,
        fontWeight: 'bold'
    },

}));

function ConnectionFailed() {
    const classes = useStyles();
    const {t} = useTranslation('translation');

    return (
        <>
            <Header/>
            <Container>
                <div className={classes.folders1}>
                    <Typography className={classes.uploadText1}>
                        {t("FAILED_TITLE")}
                    </Typography>
                    <img
                        alt={'error'}
                        src="/error.png"
                        style={{
                            width: 150,
                            height: 150,
                            marginLeft: 20,
                        }}
                    ></img>
                </div>
            </Container>
            <Footer position="fixed"/>
        </>
    );

}

export default ConnectionFailed;
