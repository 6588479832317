import {useEffect, useState} from 'react';
import api from "../utils/axios";

function usePeerOffline(startShare, connected, socket, sessionId) {
    const channelOptions = {
        ordered: true,
        protocol: 'tcp',
    };
    const [channel, setChannel] = useState(null);
    const [config, setConfig] = useState(false);
    const [signalStatus, setSignalStatus] = useState('');
    const [onlineStatus, setOnlineStatus] = useState('New');
    const [peerConnection, setPeerConnection] = useState(null);
    const [answer, setAnswer] = useState();
    const [remote, setRemote] = useState(false);
    const [offer, setOffer] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const sendToPeer = (messageType, payload, sessionId) => {
        //General socket emiter
        const object = {
            room: sessionId,
            payload
        };

        // console.log('messageType ', messageType)
        try {
            if (socket) {
                socket.emit(messageType, object)
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (!config) {
            //get credentials for rtc comunication
            api.get('/StunTurn/GetRelay').then(data => {
                // console.log('StunTurn ', data)
                const c = data.data[0]['Credential'];
                const u = data.data[0]['Username'];

                const pc_config = {
                    iceServers: [
                        // {
                        //     urls: ["stun:13.107.17.41:3478", "turn:13.107.17.41:3478"],
                        //     username: u,
                        //     credential: c
                        // },
                        // {
                        //     urls: ["stun:turn.azure.com:3478", "turn:turn.azure.com:3478"],
                        //     username: u,
                        //     credential: c
                        // }
                        {
                            urls: [
                              "stun:global.stun.twilio.com:3478"
                            ]
                          }
                        
                    ],
                };

                setPeerConnection(new RTCPeerConnection(pc_config))
                setConfig(true)
            })
        }

        return () =>{
            setOnlineStatus('New')
            setSignalStatus('New')
            setConfig(false);
        }

    }, [])


    useEffect(() => {
        if (answer && !remote && peerConnection.signalingState === 'have-local-offer') {
            // console.log('set answer ')
            peerConnection.setRemoteDescription(answer).then(() => {
                setRemote(true)
            }).catch(e => console.log('setRemoteDescription ', e))
        }

    }, [answer, peerConnection, remote, signalStatus])

    useEffect(()=>{
       
        if(startShare && !offer && peerConnection){
            // console.log('startShare')
            peerConnection.createOffer()
                .then(async offer => {
                    await peerConnection.setLocalDescription(offer).catch(e => console.log(e))
                    // console.log('send sessionId', sessionId);
                    // console.log('send offer', offer);
                    sendToPeer('offer', offer, sessionId)
                    setOffer(true)
                }).catch(e => console.log('setLocalDescription ', e))
        }
    },[startShare, offer, peerConnection, sessionId, socket, sendToPeer])

    useEffect(() => {
        if (connected && peerConnection) {
            try {
                socket.on('answer', (data) => {
                    // console.log('answer socket')
                    setAnswer(data);
                })


            } catch (e) {
                console.log(e)
            }           
        }

        if(peerConnection){

            socket.on('web_receive_candidate', (data) => {
                // console.log('web_receive_candidate data ', data)
                if (data && peerConnection) {
                    peerConnection.addIceCandidate(new RTCIceCandidate(data)).catch(e => console.log('RTCIceCandidate ', e))
                }
            })

            peerConnection.close = e => {
                setOnlineStatus('Closed')
                setSignalStatus('Closed')
            };
    
            peerConnection.onconnectionstatechange = ev => {
                // console.log('peerConnection.connectionState ', peerConnection.connectionState)
                switch (peerConnection.connectionState) {
                    case "new":
                    case "checking":
                        setOnlineStatus("Connecting...");
                        break;
                    case "connected":
                        setOnlineStatus("Online");
                        break;
                    case "disconnected":
                        setOnlineStatus("Closed");
                        break;
                    case "closed":
                        setOnlineStatus("Closed");
                        break;
                    case "failed":
                        setOnlineStatus("Closed");
                        break;
                    default:
                        setOnlineStatus("Unknown");
                        break;
                }
            }
    
            peerConnection.onsignalingstatechange = ev => {
                // console.log('peerConnection.signalingState ', peerConnection.signalingState)
                setSignalStatus(peerConnection.signalingState);
            }
    
            peerConnection.oniceconnectionstatechange = e => {
                // console.log('peerConnection.iceConnectionState ', peerConnection.iceConnectionState);
            };
    
            peerConnection.onicecandidate = e => {
                if (e.candidate) {
                    // console.log('webapp_create_candidate ')
                    sendToPeer('webapp_create_candidate', e.candidate, sessionId);
                }
            };
            const _channel = peerConnection.createDataChannel('sendKey', channelOptions);
            setChannel(_channel);
        }

        
    }, [config, peerConnection, signalStatus, sessionId, onlineStatus, connected])

    return {
        channel,
        signalStatus,
        onlineStatus,
        peerConnection
    }
}

export default usePeerOffline;
