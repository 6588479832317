import React from 'react';
import DescriptionIcon from '@material-ui/icons/Description';
import PhotoIcon from '@material-ui/icons/Photo';

function useIcon() {
    const FileTypes = [
        {
            type: 'doc',
            icon: <DescriptionIcon style={{color: 'blue'}} />,
            color: 'blue',
        },
        {
            type: 'docx',
            icon: <DescriptionIcon style={{color: 'blue'}} />,
            color: 'blue',
        },
        {
            type: 'pdf',
            icon: <DescriptionIcon style={{color: 'red'}} />,
            color: 'red',
        },
        {
            type: 'jpg',
            icon: <PhotoIcon style={{color: '#0E9C4D'}}/>,
            color: '#0E9C4D',
        },
        {
            type: 'png',
            icon: <PhotoIcon style={{color: '#0E9C4D'}}/>,
            color: '#0E9C4D',
        },
        {
            type: 'jpeg',
            icon: <PhotoIcon style={{color: '#0E9C4D'}}/>,
            color: '#0E9C4D',
        },
        {
            type: 'bmp',
            icon: <PhotoIcon style={{color: '#0E9C4D'}}/>,
            color: '#0E9C4D',
        },
    ];

    const returnIconByType = (file) => {
        try {
            const ext = file.name.split('.').pop();
//change file.type below with ext
            const typeObject = FileTypes.find((x) => x.type === ext);
            if (typeObject) {
                return typeObject;
            }
        } catch (error) {
            return { type: 'docx',
                icon: 'file-word',
                color: 'gray' };
        }

        return { icon: 'note', color: '#0A3C20' };
    };

    return {returnIconByType}
}

export default useIcon;
